const FILTER_OPTION_CONFIGURATION_NAME = {
  value: '1',
  label: '配置名称',
};
const FILTER_OPTION_CONFIGURATION_IDENTIFER = {
  value: '2',
  label: '配置标识符',
};

const FILTER_OPTIONS = [FILTER_OPTION_CONFIGURATION_IDENTIFER, FILTER_OPTION_CONFIGURATION_NAME];
const columns = [
  {
    title: '配置项标识符',
    dataIndex: 'identifier',
    key: 'identifier',
    align: 'center',
  },
  {
    title: '配置项定义唯一标识',
    dataIndex: 'encode',
    key: 'encode',
    align: 'center',
  },
  {
    title: '配置项描述',
    dataIndex: 'description',
    key: 'description',
    scopedSlots: { customRender: 'description' },
    align: 'center',
  },
  {
    title: '最后更新时间',
    dataIndex: 'lastModificationTime',
    key: 'lastModificationTime',
    scopedSlots: { customRender: 'lastModificationTime' },
    align: 'center',
  },
  {
    title: '更新人',
    dataIndex: 'updaterName',
    key: 'updaterName',
    width: '10%',
    scopedSlots: { customRender: 'updaterName' },
    align: 'center',
  },
  {
    title: '操作',
    key: 'action',
    scopedSlots: { customRender: 'action' },
    align: 'center',
  },
];
const editColumns = [
  {
    title: 'value',
    dataIndex: 'value',
    key: 'value',
    align: 'center',
  },
  {
    title: 'description',
    dataIndex: 'description',
    key: 'description',
    scopedSlots: { customRender: 'description' },
    align: 'center',
  },

  {
    title: 'originalFilename',
    dataIndex: 'originalFilename',
    key: 'originalFilename',
    align: 'center',
    scopedSlots: { customRender: 'originalFilename' },
  },
  {
    title: '操作',
    key: 'action',
    scopedSlots: { customRender: 'action' },
    align: 'center',
  },
];
const ITEM_TYPE = {
  DYNAMIC_DEPOT: 1,
  DEVICE_ADDRESS: 2,
};
export default {
  FILTER_OPTIONS,
  FILTER_OPTION_CONFIGURATION_IDENTIFER,
  FILTER_OPTION_CONFIGURATION_NAME,
  columns,
  editColumns,
  ITEM_TYPE,
};
