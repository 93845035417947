import BaseRouterNavItem from '../base/base-nav-router-item';

const productRouters = [
  new BaseRouterNavItem(
    'RegionManagement',
    'RegionManagement',
    '区域管理',
    () => import('../../page/ai-home/region/RegionManagement'),
    {
      navBarVisible: true,
      permissionCodeList: ['rcs:region:list'],
    },
  ),
  new BaseRouterNavItem(
    'productClassification',
    'productClassification',
    '产品分类管理',
    () => import('../../layout/Index'),
    {
      navBarVisible: true,
      permissionCodeList: ['rcs:product_category:list', 'rcs:product_sub_class:list'],
      redirect: '/productClassification/ProductCategoryManagement',
    },
    [
      new BaseRouterNavItem(
        'ProductCategoryManagement',
        'ProductCategoryManagement',
        '产品目录管理',
        () => import('../../page/ai-home/product-category/ProductCategoryManagement'),
        {
          navBarVisible: true,
          permissionCodeList: ['rcs:product_category:list'],
        },
      ),
      new BaseRouterNavItem(
        'productSubClassManagement',
        'ProductSubClassManagement',
        '产品小类管理',
        () => import('../../page/ai-home/product-subclass/ProductSubClassManagement'),
        {
          navBarVisible: true,
          permissionCodeList: ['rcs:product_sub_class:list'],
        },
      ),
      new BaseRouterNavItem(
        'productSubClassManagement/productSubClassCreator',
        'productSubClassCreator',
        '产品小类创建',
        () => import('../../page/ai-home/product-subclass/ProductSubClassCreator'),
        { navBarVisible: false },
      ),
      new BaseRouterNavItem(
        'productSubClassManagement/productSubClassEditor/:id',
        'productSubClassEditor',
        '产品小类编辑',
        () => import('../../page/ai-home/product-subclass/ProductSubClassEditor'),
        { navBarVisible: false },
      ),
      new BaseRouterNavItem(
        'productSubClassManagement/productSubClassDetail/:id',
        'productSubClassDetail',
        '产品小类详情',
        () => import('../../page/ai-home/product-subclass/ProductSubClassDetail'),
        { navBarVisible: false },
      ),
    ],
  ),
  new BaseRouterNavItem(
    'loadedDeviceManagement',
    'loadedDeviceManagement',
    '负载类型管理',
    () => import('../../page/ai-home/loaded-device/LoadedDeviceManagement'),
    {
      navBarVisible: true,
      permissionCodeList: ['rcs:loaded_device_type:list'],
    },
  ),
  new BaseRouterNavItem(
    'productModel',
    'productModel',
    '物模型管理',
    () => import('../../layout/Index'),
    {
      navBarVisible: true,
      permissionCodeList: [
        'rcs:product:list',
        'rcs:product_property:list',
        'rcs:product_service:list',
        'rcs:product_configuration:list',
      ],
      redirect: '/productModel/productManagement',
    },
    [
      new BaseRouterNavItem(
        'productManagement',
        'productManagement',
        '产品(物模型)管理',
        () => import('../../page/ai-home/product/ProductManagement'),
        {
          navBarVisible: true,
          permissionCodeList: ['rcs:product:list'],
        },
      ),
      new BaseRouterNavItem(
        'productManagement/productCreator',
        'productCreator',
        '产品(物模型)创建',
        () => import('../../page/ai-home/product/ProductCreator'),
        { navBarVisible: false },
      ),
      new BaseRouterNavItem(
        'productManagement/productEditor/:id',
        'productEditor',
        '产品(物模型)编辑',
        () => import('../../page/ai-home/product/ProductEditor'),
        { navBarVisible: false },
      ),
      new BaseRouterNavItem(
        'productManagement/productDetail/:id',
        'productDetail',
        '产品(物模型)详情',
        () => import('../../page/ai-home/product/ProductDetail'),
        { navBarVisible: false },
      ),
      new BaseRouterNavItem(
        'propertyManagement',
        'propertyManagement',
        '属性管理',
        () => import('../../page/ai-home/property/PropertyManagement'),
        {
          navBarVisible: true,
          permissionCodeList: ['rcs:product_property:list'],
        },
      ),
      new BaseRouterNavItem(
        '/propertyManagement/propertyCreator',
        'propertyCreator',
        '属性创建',
        () => import('../../page/ai-home/property/PropertyCreator'),
        { navBarVisible: false },
      ),
      new BaseRouterNavItem(
        'propertyManagement/propertyEditor/:id',
        'propertyEditor',
        '属性编辑',
        () => import('../../page/ai-home/property/PropertyEditor'),
        { navBarVisible: false },
      ),
      new BaseRouterNavItem(
        'propertyManagement/propertyDetail/:id',
        'propertyDetail',
        '属性详情',
        () => import('../../page/ai-home/property/PropertyDetail'),
        { navBarVisible: false },
      ),
      new BaseRouterNavItem(
        'productServiceManagement',
        'productServiceManagement',
        '服务项管理',
        () => import('../../page/ai-home/product-service/ProductServiceManagement'),
        {
          navBarVisible: true,
          permissionCodeList: ['rcs:product_service:list'],
        },
      ),
      new BaseRouterNavItem(
        'productServiceManagement/productServiceCreator',
        'productServiceCreator',
        '服务创建',
        () => import('../../page/ai-home/product-service/ProductServiceCreator'),
        { navBarVisible: false },
      ),
      new BaseRouterNavItem(
        'productServiceManagement/productServiceEditor/:id',
        'productServiceEditor',
        '服务编辑',
        () => import('../../page/ai-home/product-service/ProductServiceEditor'),
        { navBarVisible: false },
      ),
      new BaseRouterNavItem(
        'productServiceManagement/productServiceDetail/:id',
        'productServiceDetail',
        '服务详情',
        () => import('../../page/ai-home/product-service/ProductServiceDetail'),
        { navBarVisible: false },
      ),
      new BaseRouterNavItem(
        'productConfigurationManagement',
        'productConfigurationManagement',
        '配置管理',
        () => import('../../page/ai-home/product-configuration/ProductConfigurationManagement'),
        {
          navBarVisible: true,
          permissionCodeList: ['rcs:product_configuration:list'],
        },
      ),
      new BaseRouterNavItem(
        'productConfigurationManagement/productConfigurationCreator',
        'productConfigurationCreator',
        '配置创建',
        () => import('../../page/ai-home/product-configuration/ProductConfigurationCreator'),
        { navBarVisible: false },
      ),
      new BaseRouterNavItem(
        'productConfigurationManagement/productConfigurationEditor/:id',
        'productConfigurationEditor',
        '配置编辑',
        () => import('../../page/ai-home/product-configuration/ProductConfigurationEditor'),
        { navBarVisible: false },
      ),
      new BaseRouterNavItem(
        'productConfigurationManagement/productConfigurationDetail/:id',
        'productConfigurationDetail',
        '配置详情',
        () => import('../../page/ai-home/product-configuration/ProductConfigurationDetail'),
        { navBarVisible: false },
      ),
      new BaseRouterNavItem(
        'productModelManagement',
        'productModelManagement',
        '型号管理',
        () => import('../../page/ai-home/product-model/ProductModelManagement'),
        {
          navBarVisible: true,
          permissionCodeList: ['rcs:product_internal_model:list'],
        },
      ),
      new BaseRouterNavItem(
        'productModelManagement/productModelCreator',
        'productModelCreator',
        '型号创建',
        () => import('../../page/ai-home/product-model/ProductModelCreator'),
        {
          navBarVisible: false,
        },
      ),
      new BaseRouterNavItem(
        'productModelManagement/productModelEditor/:id',
        'productModelEditor',
        '型号编辑',
        () => import('../../page/ai-home/product-model/ProductModelEditor'),
        {
          navBarVisible: false,
        },
      ),
      new BaseRouterNavItem(
        'productModelManagement/productModelDetail/:id',
        'productModelDetail',
        '型号详情',
        () => import('../../page/ai-home/product-model/ProductModelDetail'),
        {
          navBarVisible: false,
        },
      ),
    ],
  ),
  new BaseRouterNavItem(
    'loadedDeviceManagement/loadedDeviceCreator',
    'loadedDeviceCreator',
    '负载类型创建',
    () => import('../../page/ai-home/loaded-device/LoadedDeviceCreator'),
    { navBarVisible: false },
  ),
  new BaseRouterNavItem(
    'loadedDeviceManagement/loadedDeviceEditor/:id',
    'loadedDeviceEditor',
    '负载类型编辑',
    () => import('../../page/ai-home/loaded-device/LoadedDeviceEditor'),
    { navBarVisible: false },
  ),
  new BaseRouterNavItem(
    'loadedDeviceManagement/loadedDeviceDetail/:id',
    'loadedDeviceDetail',
    '负载类型详情',
    () => import('../../page/ai-home/loaded-device/LoadedDeviceDetail'),
    { navBarVisible: false },
  ),
  new BaseRouterNavItem(
    'i18n',
    'i18n',
    '国际化管理',
    () => import('../../layout/Index'),
    {
      navBarVisible: true,
      permissionCodeList: [
        'rcs:i18n:list',
        'rcs:entry_i18n_language:list',
        'rcs:entry_i18n_entity:list',
        'rcs:entry_i18n:list',
      ],
      redirect: '/i18n/languageManagement',
    },
    [
      new BaseRouterNavItem(
        'languageManagement',
        'languageManagement',
        '语言管理',
        () => import('../../page/ai-home/language/LanguageManagement'),
        {
          navBarVisible: true,
          permissionCodeList: ['rcs:entry_i18n_language:list'],
        },
      ),
      new BaseRouterNavItem(
        'i18nEntityManagement',
        'i18nEntityManagement',
        '实体管理',
        () => import('../../page/ai-home/i18n-entity/I18nEntityManagement'),
        {
          navBarVisible: true,
          permissionCodeList: ['rcs:entry_i18n_entity:list'],
        },
      ),
      new BaseRouterNavItem(
        'entryManagement',
        'entryManagement',
        '词条管理',
        () => import('../../page/ai-home/entry/EntryManagement'),
        { navBarVisible: true, permissionCodeList: ['rcs:entry_i18n:list'] },
      ),
    ],
  ),
  new BaseRouterNavItem(
    'HolidaysManagement',
    'HolidaysManagement',
    '节假日管理',
    () => import('../../page/ai-home/holidays/HolidaysManagement'),
    {
      navBarVisible: true,
      permissionCodeList: ['rcs:date_information:list'],
    },
  ),
  new BaseRouterNavItem(
    'CpuManagement',
    'CpuManagement',
    '中控功能管理',
    () => import('../../layout/Index'),
    {
      navBarVisible: true,
      permissionCodeList: [
        'rcs:cpu_model:list',
        'rcs:cpu_hard_version:list',
        'rcs:cpu_soft_version:list',
        'rcs:cpu_feature:list',
        'rcs:cpu_feature_relation:list',
      ],
      redirect: '/CpuManagement/cpuModelManagement',
    },
    [
      new BaseRouterNavItem(
        'cpuModelManagement',
        'cpuModelManagement',
        '中控型号管理',
        () => import('../../page/ai-home/cpu-model/CpuModelManagement'),
        { navBarVisible: true, permissionCodeList: ['rcs:cpu_model:list'] },
      ),
      new BaseRouterNavItem(
        'cpuHardwareManagement',
        'cpuHardwareManagement',
        '中控硬件版本管理',
        () => import('../../page/ai-home/cpu-hardware/CpuHardwareManagement'),
        { navBarVisible: true, permissionCodeList: ['rcs:cpu_hard_version:list'] },
      ),
      new BaseRouterNavItem(
        'cpuSoftwareManagement',
        'cpuSoftwareManagement',
        '中控软件版本管理',
        () => import('../../page/ai-home/cpu-software/CpuSoftwareManagement'),
        { navBarVisible: true, permissionCodeList: ['rcs:cpu_soft_version:list'] },
      ),
      new BaseRouterNavItem(
        'cpuFunctionManagement',
        'cpuFunctionManagement',
        '中控功能管理',
        () => import('../../page/ai-home/cpu-function/CpuFunctionManagement'),
        { navBarVisible: true, permissionCodeList: ['rcs:cpu_feature:list'] },
      ),
      new BaseRouterNavItem(
        'cpuRelationManagement',
        'cpuRelationManagement',
        '中控功能关联管理',
        () => import('../../page/ai-home/cpu-relation/CpuRelationManagement'),
        { navBarVisible: true, permissionCodeList: ['rcs:cpu_feature_relation:list'] },
      ),
    ],
  ),
  new BaseRouterNavItem(
    'ScenceTypeManagement',
    'ScenceTypeManagement',
    '场景类型管理',
    () => import('../../page/ai-home/scence-type/ScenceTypeManagement'),
    {
      navBarVisible: true,
      permissionCodeList: ['rcs:scene_type:list'],
    },
  ),
  new BaseRouterNavItem(
    'PlatformManagement',
    'PlatformManagement',
    '平台管理',
    () => import('../../page/ai-home/platform/PlatformManagement'),
    {
      navBarVisible: true,
      permissionCodeList: ['rcs:platform:list'],
    },
  ),
];

export default productRouters;
