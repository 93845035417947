import navRouters from './models/nav-router';
import errorRouters from './models/error';
import productRouters from './models/aihome-router';
import BaseRouterNavItem from './base/base-nav-router-item';
import ModelRouters from './models/model-router';
import Main from '../page/MainPage';

const subsystemRouters = [...navRouters, ...productRouters];

const routers = [
  {
    path: '/',
    name: 'Main',
    component: Main,
    children: [
      ...subsystemRouters,
      ...errorRouters,
    ],
  },
  new BaseRouterNavItem('/login', 'Login', '登录', () => import('../page/login/Login'), {}),
  {
    path: '/model',
    name: 'model',
    component: () => import('../page/physicalModel/PhysicalModel'),
    children: ModelRouters,
    redirect: '/model/ModelProductList',
    meta: {
      requireLogin: false,
    },
  },
];

export { routers, subsystemRouters };
