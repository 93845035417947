<template>
  <a-config-provider :locale="locale">
    <a-layout id="components-layout-demo-side" style="min-height: 100vh">
      <a-layout-sider class="layout-side" :collapsed="collapsed" :title="title">
        <div class="logo" v-if="!collapsed">NexHome</div>
        <side-bar ref="sidebar"></side-bar>
      </a-layout-sider>
      <a-layout>
        <tool-bar @updateOpenKeys="updateOpenKeys"></tool-bar>
        <a-page-header
          id="page-header-toolbar"
          style="border: 1px solid rgb(235, 237, 240)"
          :breadcrumb="{ props: { routes: breadcrumbRoute } }"
        ></a-page-header>
        <div class="layout-content">
          <a-layout-content class="layout-card" >
            <a-spin size="large" :spinning="hasRequest">
              <LayoutIndex></LayoutIndex>
            </a-spin>
          </a-layout-content>
        </div>
        <a-layout-footer style="text-align: center">RCS Management UI ©2020 Created by Nexhome</a-layout-footer>
      </a-layout>
    </a-layout>
  </a-config-provider>
</template>
<script>
import { mapGetters } from 'vuex';
import zhCN from 'ant-design-vue/lib/locale-provider/zh_CN';
import SideBar from '../components/side-bar/SideBar';
import ToolBar from '../components/tool-bar/ToolBar';
import eventBus from '../core/event-bus';
import LayoutIndex from '../layout/Index';

export default {
  mounted() {
    if (!this.$store.state.currentUser?.accessToken) {
      eventBus.$emit('logout');
    }
  },
  computed: {
    ...mapGetters(['hasRequest']),
    breadcrumbRoute() {
      return this.$route.matched
        .filter((route) => route.meta && route.meta.title)
        .map((route) => ({
          path: route.path.split('/')[route.path.split('/').length - 1],
          breadcrumbName: route.meta.title,
        }));
    },
  },
  components: {
    SideBar,
    ToolBar,
    LayoutIndex,
  },
  data() {
    return {
      locale: zhCN,
      collapsed: this.$store.state.sidebarType,
      title: 'Rcs Management',
    };
  },
  watch: {
    hasRequest(value) {
      return value;
    },
  },
  methods: {
    updateOpenKeys(params) {
      this.$refs.sidebar.updateOpenKeys(params);
    },
  },
};
</script>

<style lang="less" scoped>
#components-layout-demo-side .logo {
  height: 32px;
  color: white;
  margin: 16px;
  font-size: 20px;
  font-weight: 600;
}
.layout-content {
  margin: 24px 24px 24px;
  .layout-card {
    position: relative;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    min-height: 80vh;
    margin: 0px;
    padding: 0;
    background: white;
    color: rgba(0, 0, 0, 0.65);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5;
    list-style: none;
    border-radius: 2px;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    -webkit-font-feature-settings: 'tnum';
    font-feature-settings: 'tnum';
  }
}
#components-layout-demo-side .ant-page-header {
  background: white;
  box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);
  padding-top: 0px 15px;
  .ant-page-header-content {
    padding-top: 0px;
  }
}
</style>
