<template>
  <div class="shadow">
    <header class="a-header header-layout">
      <div class="header-title">
        <h3  style="margin-left: 1rem; cursor:pointer;" @click="handleCreateNewWindow">RCS管理平台</h3>
      </div>
      <div class="header-right-layout">
        <a-popover trigger="hover">
          <template slot="content">
            <div class="personal-ctrl">
              <!-- <p @click="edit">编辑</p> -->
              <p v-if="$hasPermissions(['rcs:ldap_user:list'])" @click="handleUser">用户管理</p>
              <p v-if="$hasPermissions(['rcs:role:list'])"  @click="handleRole">角色管理</p>
              <p @click="exit">注销</p>
            </div>
          </template>
          <div class="user-box">
            <a-avatar size="small" icon="user" />
            <a-button class="user-name" type="link">{{
              $store.state.currentUser ? $store.state.currentUser.realName : '-'
            }}</a-button>
          </div>
          <!-- <a-icon type="pic-right" :style="{ fontSize: '20px', color: '#000000' }" /> -->
        </a-popover>
      </div>
    </header>
    <UserModal ref="userModal" />
    <RoleModal ref="roleModal"/>
  </div>
</template>

<script>
import lodash from 'lodash';
import { subsystemRouters } from '@/router/routers';
import eventBus from '../../core/event-bus';
import UserModal from '../user-modal/UserModal';
import RoleModal from '../role-modal/RoleModal';
import store from '../../core/store';

export default {
  components: { UserModal, RoleModal },
  name: 'ToolBar',
  data() {
    return {
      userName: '',
      collapsed: this.$store.state.sidebarType,
      systemRouters: subsystemRouters,
    };
  },
  methods: {
    exit() {
      this.removeUserInfoFromLocalStorage();
      this.$router.push({ name: 'Login' });
    },
    removeUserInfoFromLocalStorage() {
      this.$store.commit('removeUserInfo');
    },
    edit() {},
    test() {
      this.$router.push({ name: 'productProviderList' });
    },
    toggleCollapsed() {
      this.collapsed = !this.collapsed;
      this.$store.commit('setSidebarType', this.collapsed);
    },
    handleUser() {
      this.$refs.userModal.openModel();
    },
    handleRole() {
      this.$refs.roleModal.showRoleModal();
    },
    findHasPermissionChildRoute(route) {
      for (let index = 0; index < route.children.length; index += 1) {
        const children = route.children[index];
        // 非叶子节点继续递归
        if (children.children) {
           const result = this.findHasPermissionChildRoute(children);
           if (result) {
            return result;
           }
        }
        if (lodash.intersection(children.meta.permissionCodeList, store.state.currentUser.permissions).length) {
          return {
            parent: route,
            children,
          };
        }
      }
      return null;
    },
    handleRouteJump(route) {
      // 实际跳转的是区域管理和服务商管理
      const to = route.children[0];
      if (lodash.intersection(to.meta.permissionCodeList, store.state.currentUser.permissions).length) {
        this.$router.push({ name: route.name });
      } else {
        const routes = this.findHasPermissionChildRoute(route);
        if (routes) {
        const { parent, children } = routes;
          this.$router.push({ name: children.name });
          if (parent) {
            this.$emit('updateOpenKeys', parent);
          }
        } else {
          this.$router.push({ name: 'error403' });
        }
      }
    },
    handleCreateNewWindow() {
      window.open(`${window.location.origin}/`, '_target');
    },
  },
  mounted() {
    eventBus.$on('logout', () => {
      this.exit();
    });
  },
};
</script>

<style lang="less" scoped>
.shadow {
  box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);
}
p {
  width: 100px;
  text-align: center;
}
.header-layout {
  display: flex;
  justify-content: space-between;
  height: 64px;
  width: 100%;
  background: white;
  color: white;
  .header-right-layout {
    margin-right: 4%;
    display: flex;
    align-items: center;
    .setting-layout {
      margin-right: 0px;
    }
    .function-icon-layout {
      margin: 0 15px;
      display: flex;
      align-items: flex-end;
      .selector {
        cursor: pointer;
      }
      .icon {
        width: 25px;
        margin-right: 3px;
      }
    }
    .user-box {
      display: flex;
      align-items: center;
      justify-content: center;
      .user-name {
        padding: 0 5px;
      }
    }
  }
  .role-layout {
    margin-right: 20px;
    font-size: 14px;
  }
}
.header-title {
  .showMenu-btn {
    border: none;
  }
  :first-child {
    margin: 0;
  }
  align-self: center;
  font-size: 1rem;
  .say-hello {
    text-align: left;
  }
}

.user-info-layout {
  cursor: pointer;
  display: flex;
  align-self: center;
  font-size: 0.8rem;
  margin-right: 1rem;
}
.personal-ctrl {
  p {
    text-align: center;
    cursor: pointer;
    &:hover {
      color: #409eff;
    }
  }
}
.user-info-text {
  align-self: center;
}
</style>
