import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';
// import { subsystemRouters } from '../router/routers';
import lodash from 'lodash';
import utils from '../common/utils';
import userData from '../data/const/user';

Vue.use(Vuex);

function getPlatformList(state) {
  if (!state.currentUser) {
    return [];
  }
  if (state.currentRole === userData.roles.afterSales || state.currentRole === userData.roles.worker) {
    return state.currentUser.relatedLinks;
  }
  if (state.currentRole === userData.roles.platformManager) {
    return state.currentUser.platformRelations;
  }
  if (state.currentRole === userData.roles.admin) {
    return state.currentUser.allLinks;
  }
  return [];
}

const store = new Vuex.Store({
  plugins: [createPersistedState()],
  state: {
    sidebarType: false,
    subsystemRouters: null,
    curRequestSet: [],
    curUploadProgress: null,
    currentRoute: null,
    currentUser: {
      token: 'thisistoken',
    },
    currentRole: null,
    currentPlatform: null,
    productList: null,
    metaData: {},
  },
  getters: {
    hasRequest: (state) => {
      if (state.curRequestSet.length) {
        return true;
      }
      return false;
    },
    currentPlatformList: (state) => getPlatformList(state),
    permissionInclude: (state) => (roles) => {
      let hasPermission = false;
      if (roles instanceof Array) {
        if (state.currentUser) {
          roles.forEach((role) => {
            if (role === state.currentRole) {
              hasPermission = true;
            }
          });
        }
      }
      return hasPermission;
    },
    isProjectLeader: (state) => (orgId) => state.currentUser.projectsAsLeader
      && !!state.currentUser.projectsAsLeader.find((project) => project.orgId === orgId),
    isSomeProjectLeader: (state) => state.currentUser.projectsAsLeader && state.currentUser.projectsAsLeader.length > 0,
  },
  mutations: {
    initData(state) {
      state.curRequestSet = [];
      // state.subsystemRouters = subsystemRouters;
    },
    setNavRouter(state, router) {
      state.navRouter = router;
    },
    setSidebarType(state, type) {
      state.sidebarType = type;
    },
    setCurrentPlatform(state, platform) {
      state.currentPlatform = platform;
    },
    setUserInfo(state, userInfo) {
      state.currentUser = userInfo;
    },
    setMetaData(state, metaData) {
      state.metaData = metaData;
    },
    setCurrentRole(state, role) {
      state.currentRole = role;
      if (state.currentRole !== userData.roles.admin) {
        const platformList = getPlatformList(state);
        if (platformList.length) {
          state.currentPlatform = platformList[0];
        }
      } else {
        state.currentPlatform = null;
      }
    },
    removeUserInfo(state) {
      state.currentUser = null;
    },
    setUploadProgress(state, progress) {
      state.curUploadProgress = progress;
    },
    startRequest(state, url) {
      state.curRequestSet.push(url);
    },
    endRequest(state, url, clear) {
      if (clear) {
        state.curRequestSet = [];
      } else {
        utils.array.removeContained(state.curRequestSet, url);
      }
    },
    setCurrentRoute(state, currentRoute) {
      if (currentRoute) {
        lodash.remove(currentRoute, (children) => children.meta.navBarVisible === false);
        state.subsystemRouters = currentRoute;
      }
    },
  },
});

export default store;
