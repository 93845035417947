import Vue from 'vue';
import VueRouter from 'vue-router';
import lodash from 'lodash';
import store from '../core/store';
import storeEvent from '../data/const/store-event';
import { routers, subsystemRouters } from './routers';

Vue.use(VueRouter);
const routes = routers;
const router = new VueRouter({
  // mode: 'history',
  // base: process.env.NODE_PROXY_HOST,
  routes,
});

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

const findWillGoRoute = (routerList, userPermissions) => routerList.find(
    (route) => route.meta
      && route.meta.permissionCodeList
      && lodash.intersection(route.meta.permissionCodeList, userPermissions).length !== 0,
  ) || {};
const whiteList = ['/login'];
router.beforeEach((to, from, next) => {
  if (to.matched.length === 0) {
    // 匹配前往的路由不存在
    next('/error/error404');
    return;
  }
  // 无需登录的路由
  if (to.meta.requireLogin === false) {
    next();
    return;
  }
  // 未登录且不在白名单，重定向到登录界面
  if (whiteList.indexOf(to.path) === -1 && !store.state.currentUser?.accessToken) {
    next(`/login?redirect=${to.path}`);
    return;
  }
  // 已登录
  if (store.state.currentUser?.accessToken) {
    //  前往首页的路由,根据用户权限判断该跳转的路由
    if (to.fullPath === '/') {
      // 可跳转的列表页
      const userPermissions = store.state.currentUser?.permissions.filter((permission) => permission.includes('list')) ?? [];
      let willGoRoute = findWillGoRoute(subsystemRouters, userPermissions);
      let nextRoute;
      if (willGoRoute) {
        // 跳转具体路由
        if (willGoRoute.children) {
          nextRoute = willGoRoute ? `/${willGoRoute.path}` : '/';
          willGoRoute = findWillGoRoute(willGoRoute.children, userPermissions);
          nextRoute += `/${willGoRoute.path}`;
        } else {
          nextRoute = willGoRoute ? `/${willGoRoute.path}` : '/';
        }
        next(nextRoute);
        return;
      }
      next('/error/error403');
      return;
    }
    // 其他路由，判断是否有权限
    if (to.meta.permissionCodeList) {
      if (lodash.intersection(to.meta.permissionCodeList, store.state.currentUser.permissions).length === 0) {
        next('/error/error403');
        return;
      }
    }
  }
  store.commit(storeEvent.EVENT_SET_CURRENT_ROUTE, subsystemRouters);
  next();
});

export default router;
